import { EIP155Lib } from '@/lib/web3-access';

export let eip155Wallets: EIP155Lib;

/**
 * Utility function to initialize and connect EIP155 wallet.
 */
export async function initializeEIP155Wallet() {
  eip155Wallets = EIP155Lib.init();
}
