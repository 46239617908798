import { JsonRpcProvider } from 'ethers';

import { EIP155Chain } from '@/lib/web3-access';

/**
 * Infura and other RPC providers limit the max amount included in a batch RPC call.
 * Ethers uses 100 by default which is too high for i.e. Infura.
 */
const BATCH_MAX_COUNT = 10;

export const createWeb3Provider = (chain: EIP155Chain, customRpc?: string): JsonRpcProvider | undefined => {
  const url = customRpc || chain.rpc;
  if (!url) return;

  return new JsonRpcProvider(url, undefined, {
    staticNetwork: true,
    batchMaxCount: BATCH_MAX_COUNT,
  });
};
