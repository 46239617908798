const requestQueue: (() => Promise<void>)[] = [];
const requestState = { inProgress: false };

export const enqueueRequest = (appRequest: () => Promise<void>) => {
  if (requestQueue.length > 0 || requestState.inProgress) {
    requestQueue.push(appRequest);
  } else {
    requestState.inProgress = true;
    appRequest().then(() => {
      requestState.inProgress = false;
      dequeueRequest();
    });
  }
};

const dequeueRequest = () => {
  if (requestQueue.length > 0) {
    setTimeout(() => {
      const nextAppRequest = requestQueue.shift();

      if (nextAppRequest) {
        requestState.inProgress = true;
        nextAppRequest().then(() => {
          requestState.inProgress = false;
          dequeueRequest();
        });
      }
    }, 2000);
  }
};
