/**
 * Adjusts the EIP712 signature v byte to avoid errors when validating the signature
 * @param signedData - The signed data
 * @param primaryType - The primary type of the signature
 * @param domainName - The domain name of the signature
 * @returns The adjusted EIP712 signature
 */
export function adjustEIP712Signature(signedData: string, primaryType: string, domainName?: string): string {
  let adjustedSignature = signedData;

  const isPermitSingle = primaryType === 'PermitSingle';
  const isPermit = primaryType === 'Permit';
  const isFusion = domainName?.includes('1inch') && primaryType === 'Order';

  if (isPermitSingle || isFusion) {
    adjustedSignature = signedData.slice(0, -2);
  } else if (isPermit) {
    adjustedSignature = signedData.slice(0, 130) + '1C';
  }

  return adjustedSignature;
}
