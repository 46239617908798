import { Web3 } from 'web3';
import BigNumber from 'bignumber.js';
import { isHexStrict } from 'web3-validator';

/**
 * Converts Wei to Ether.
 *
 * This function takes a string representing a value in Wei (the smallest unit of Ether) and converts it
 * to a string representing the value in Ether. The conversion is done using the web3.js library.
 *
 * @param value - The value in Wei as a string.
 * @param fullDecimals - Optional parameter to specify whether to include all decimal places or not. Default is false.
 * @returns The converted value in Ether as a string. If the value is zero, the string '0' is returned.
 *          For very small values (less than one millionth), the result is returned with significant digits
 *          shown using toPrecision. For other values, the result is formatted with a fixed number of decimal places.
 */
export function convertWeiToEther(value: string, fullDecimals: boolean = false): BigNumber {
  const web3 = new Web3();
  const etherValue = web3.utils.fromWei(value, 'ether');
  const numberValue = new BigNumber(etherValue);

  // If the number is zero or less, return the BigNumber representation of '0' directly
  if (numberValue.isZero() || numberValue.isNaN()) {
    return new BigNumber(0);
  }

  // For very small numbers (less than one millionth), use toPrecision to ensure that significant digits are shown
  if (numberValue.isLessThan(1e-6)) {
    return new BigNumber(numberValue.toPrecision(1));
  }

  // If fullDecimals is true, return the value with all decimal places
  if (fullDecimals) {
    return new BigNumber(numberValue.toFixed());
  } else {
    // For other numbers, format them with a fixed number of decimal places
    return new BigNumber(numberValue.toFixed(6));
  }
}

/**
 * Converts Gwei to Wei.
 *
 * @param value - The value in Gwei to be converted to Wei.
 * @returns The value in Wei as a string.
 */
export function convertGweiToWei(value: string): BigNumber {
  const web3 = new Web3();

  if (Number.isNaN(value) || !Number(value)) {
    return new BigNumber(0);
  }

  const gasPriceWei = web3.utils.toWei(value, 'gwei');
  return new BigNumber(gasPriceWei);
}

/**
 * Converts Wei to Gwei.
 *
 * @param value - The value in wei to be converted to Gwei.
 * @returns The value in Wei as a string.
 */
export function convertWeiToGwei(value: string): BigNumber {
  const web = new Web3();

  if (Number.isNaN(value)) {
    return new BigNumber(0);
  }

  const gwei = web.utils.fromWei(value, 'gwei');
  return new BigNumber(gwei);
}

/**
 * Converts hex to utf8 string if it is valid bytes
 */
export function convertHexToUtf8(value: string) {
  const web3 = new Web3();

  if (isHexStrict(value)) {
    return web3.utils.hexToUtf8(value);
  }

  return value;
}

/**
 * Converts a hex string to an ascii string
 * @param message The hex string to convert
 * @returns The ascii string
 */
export function hexToAscii(message?: string): string {
  if (!message) return '';

  if (message.startsWith('0x')) {
    const hex = message.replace('0x', '');
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      const charCode = Number.parseInt(hex.slice(i, i + 2), 16);
      if (charCode >= 32 && charCode <= 126) {
        // Only include printable ASCII characters
        str += String.fromCodePoint(charCode);
      }
    }
    return str;
  }

  return message;
}

/**
 * Converts sats to BTC.
 * @param sats - The sats value.
 * @returns The BTC value as a string.
 */
export function convertSatsToBTC(sats: string): string {
  try {
    const satsBN = new BigNumber(sats);
    const btc = satsBN.dividedBy(1e8);

    return btc.toFixed();
  } catch (err: any) {
    console.error(err.message);
    return `Error converting sats to BTC: ${err.message}`;
  }
}

/**
 * Converts Tron Sun to TRX.
 * 1 TRX = 1,000,000 Sun
 * @param sun - The Sun value.
 * @returns The TRX value as a string.
 */

export function convertSunToTRX(sun: string): string {
  try {
    const sunBN = new BigNumber(sun);
    const trx = sunBN.dividedBy(1e6);

    return trx.toFixed();
  } catch (err: any) {
    console.error(err.message);
    return `Error converting Sun to TRX: ${err.message}`;
  }
}

/**
 * Converts a float Ether value to its hexadecimal representation in Wei.
 *
 * @param etherValue - The float value in Ether to convert (e.g., "0.1").
 * @returns The hexadecimal string representation of the value in Wei.
 */
export function convertEtherToHex(etherValue: string): string {
  const web3 = new Web3();
  try {
    const weiValue = web3.utils.toWei(etherValue, 'ether');
    return web3.utils.numberToHex(weiValue);
  } catch (err) {
    console.error('Error converting Ether to Hex Wei:', err);
    return '0x0';
  }
}
