import { isAddress } from 'web3-validator';

import { convertHexToUtf8 } from '@/lib/web3-utils';
import { EIP712, SignatureType } from '@/lib/web3-access';

/**
 * Truncates string (in the middle) via given lenght value
 */
export function truncate(value: string, length: number) {
  if (value?.length <= length) return value;

  const separator = '...';
  const stringLength = length - separator.length;
  const frontLength = Math.ceil(stringLength / 2);
  const backLength = Math.floor(stringLength / 2);

  return value.slice(0, Math.max(0, frontLength)) + separator + value.slice(Math.max(0, value.length - backLength));
}

/**
 * Gets message from various signing request methods by filtering out
 * a value that is not an address (thus is a message).
 * If it is a hex string, it gets converted to utf8 string
 */
export function getSignParamsMessage(params: string[]) {
  const message = params.find((p) => !isAddress(p));

  return message ? convertHexToUtf8(message) : '';
}

/**
 * Gets data from various signTypedData request methods by filtering out
 * a value that is not an address (thus is data).
 * If data is a string convert it to object
 */
export function getSignTypedDataParamsData(params: string[]) {
  const data = params.find((p) => !isAddress(p));

  if (typeof data === 'string') return JSON.parse(data);

  return data;
}

/**
 * Get our address from params checking if params string contains one
 * of our wallet addresses
 */
export function getWalletAddressFromParams(addresses: string[], params: any) {
  const paramsString = JSON.stringify(params);
  let address = '';

  for (const addr of addresses) {
    if (paramsString.toLowerCase().includes(addr.toLowerCase())) address = addr;
  }

  return address;
}

/**
 * Encodes a message to follow the EIP-191 standard
 * @see {@link https://eips.ethereum.org/EIPS/eip-191}
 * @param msg The message to encode
 * @returns The encoded message
 */
export function encodeEIP191Message(msg: string): string {
  // eslint-disable-next-line unicorn/no-hex-escape
  return `\x19Ethereum Signed Message:\n${msg.length.toString()}${msg}`;
}

/**
 * Encodes a message to follow the EIP-191 standard and returns it as hex
 * @param msg The message to encode
 * @returns The encoded message as hex
 */
export function encodeEIP191MessageAsHex(msg: string): string {
  const message = encodeEIP191Message(msg);
  return Buffer.from(message, 'utf8').toString('hex');
}

/**
 * Checks if the given message is an EIP712 object.
 * @param message The message to check.
 * @returns Whether the message is an EIP712 object.
 */
export function isEIP712(message: unknown): message is EIP712 {
  return (
    message !== null &&
    typeof message === 'object' &&
    'primaryType' in message &&
    'domain' in message &&
    'message' in message
  );
}

/**
 * Gets the signature type from the given content type
 * @param contentType The content type to get the signature type from
 * @returns The signature type
 */
export function getSignatureType(contentType?: string | null | undefined): SignatureType | '' {
  switch (contentType) {
    case 'application/eip712':
    case 'application/x-eip712+json': {
      return SignatureType.EIP712;
    }
    case 'application/eip155':
    case 'text/plain;charset=UTF-8':
    case 'text/plain': {
      return SignatureType.EIP155;
    }
    default: {
      return '';
    }
  }
}
